// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-virtual-index-tsx": () => import("./../../../src/pages/virtual/index.tsx" /* webpackChunkName: "component---src-pages-virtual-index-tsx" */),
  "component---src-pages-virtual-stream-2020-12-18-tsx": () => import("./../../../src/pages/virtual/stream-2020-12-18.tsx" /* webpackChunkName: "component---src-pages-virtual-stream-2020-12-18-tsx" */),
  "component---src-pages-virtual-stream-2021-03-19-tsx": () => import("./../../../src/pages/virtual/stream-2021-03-19.tsx" /* webpackChunkName: "component---src-pages-virtual-stream-2021-03-19-tsx" */),
  "component---src-pages-virtual-stream-2021-06-11-tsx": () => import("./../../../src/pages/virtual/stream-2021-06-11.tsx" /* webpackChunkName: "component---src-pages-virtual-stream-2021-06-11-tsx" */),
  "component---src-pages-virtual-stream-2021-09-17-tsx": () => import("./../../../src/pages/virtual/stream-2021-09-17.tsx" /* webpackChunkName: "component---src-pages-virtual-stream-2021-09-17-tsx" */)
}

